<template>
  <div>
    <nexus-hq-beta></nexus-hq-beta>
    <text-module title="Leaderboard Search"></text-module>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject } from "vue";
import NexusHQBeta from "@/components/modules/NexusHQBeta.vue";
import { Search } from "@/plugins/search";
import { unwrap } from '@/util';

export default defineComponent({
  components: {
    "nexus-hq-beta": NexusHQBeta,
  },
  setup() {
    const search: Search = unwrap(inject("search"));
  },
});
</script>
